<template>
  <div class="d-flex flex-row flex-wrap login">
    <div class="d-flex justify-center align-center login__form-wrapper">
      <div class="text-center welcome">
        <img src="@/assets/images/logo-login.png" alt="login">
        <div class="welcome__description mb-6 mt-2"> Por favor ingresa con tus credenciales</div>

        <validation-observer ref="form">
          <form novalidate autocomplete="off" @submit.prevent="login">
            <validation-provider
              v-slot="{ errors }"
              name="Usuario"
              rules="required"
            >
              <v-text-field
                vid="username"
                v-model="model.username"
                type="username"
                outlined
                dense
                :error-messages="errors"
                label="Usuario"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              vid="password"
              v-slot="{ errors }"
              name="Contraseña"
              rules="required"
            >
              <v-text-field
                v-model="model.password"
                type="password"
                outlined
                dense
                :error-messages="errors"
                label="Contraseña"
                required
              ></v-text-field>
            </validation-provider>
            <v-btn block class="elevation-0 text-none" color="primary" type="submit">
              Ingresar
            </v-btn>
          </form>
        </validation-observer>

        <img src="@/assets/images/vertical-logo-strip.png" alt="vertical-logo-strip">
      </div>
    </div>
    <div class="login__banner">
    </div>
  </div>
</template>

<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import { LOGIN_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import notificationMixin from '@/mixins/notificationMixin'
import sessionMixin from '@/mixins/sessionMixin'
import routerMixin from '@/mixins/routerMixin'

export default {
  name: 'Login',
  data: () => ({
    loginUrl: LOGIN_URL,
    model: {
      username: '',
      password: ''
    }
  }),
  beforeRouteEnter (to, from, next) {
    if (sessionMixin.methods.isLoggedIn()) {
      const page = routerMixin.methods.goTo(sessionMixin.methods)
      next(page)
    } else {
      next()
    }
  },
  mixins: [
    crudServiceMixin,
    sessionMixin,
    loaderMixin,
    formOperationsMixin,
    notificationMixin,
    routerMixin
  ],
  methods: {
    login () {
      this.executeWithFormValidation(async () => {
        this.showLoader()
        this.post(this.loginUrl, this.model).then((response) => {
          const userInfo = response.data
          this.saveToLocalStorage(userInfo)
          const page = this.goTo(this)
          this.$router.go(page.path)
          this.hideLoader()
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$refs.form.errors.password.push('Credenciales incorrectas')
          }
          this.hideLoader()
        })
      })
    }
  }
}
</script>

<style lang="scss">
.login {
  height: 100vh;

  &__form-wrapper {
    width: 100%;
  }

  &__banner {
    background: url("../../../assets/images/login-banner.jpg") no-repeat center;
    background-size: cover;
    display: none;
    width: 60%;
  }
}

@media (min-width: 990px) {
  .login {
    &__form-wrapper {
      width: 40%;
    }

    &__banner {
      display: flex;
    }
  }
}

.welcome {
  width: 320px;

  img {
    width: 320px;
  }

  &__title {
    font-size: 30px;
    font-family: 'Roboto Bold', sans-serif !important;
  }

  &__description {
    color: #808080;
    line-height: 10px
  }

}

</style>
